<template>
  <div class="d-flex justify-content-center">
    <b-card class="content-card">
      <p>
        To be added ...
      </p>
      <p>
        <b-link
          href="https://www.futurevc.co.uk/"
          target="_blank"
        >
          About FVC
        </b-link>
        <feather-icon icon="ExternalLinkIcon" />
      </p>
      <p>
        <b-link
          href="https://www.structuredretailproducts.com/about-us"
          target="_blank"
        >
          About SRP
        </b-link>
        <feather-icon icon="ExternalLinkIcon" />
      </p>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BLink,
  },
}
</script>

<style scoped>
.content-card {
  width: 75%
}

@media screen and (max-width: 1024px) {
  .content-card {
    width: 100%;
  }
}
</style>
